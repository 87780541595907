import Button  from '../Button/Button'
import React, {useRef} from 'react'
import "./Form.css"
import { useTranslation} from 'react-i18next';
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";

export default function Form() {

  const {t} = useTranslation();
  const form = useRef();
  const {
    resetField,
    register
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      hdykm: "",
      message: "",
    }
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2slg357', 'template_g00yz1p', form.current, 'jfM0wfC8UpPhsoKHN')
    .then((result) => {
      // console.log("sent!");
    }, (error) => {
      // console.log("not sent");
    });

    resetField("firstName")
    resetField("lastName")
    resetField("phoneNumber")
    resetField("email")
    resetField("hdykm")
    resetField("message")
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className='form-control'>
        <label>{t("Form.firstName")}</label>
        <input id="firstName" type='text' name="firstName" {...register("firstName")} />
      </div>

      <div className='form-control'>
        <label>{t("Form.lastName")}</label>
        <input id="lastName" type='text' name="lastName" {...register("lastName")}/>
      </div>

      <div className='form-control'>
        <label>{t("Form.Phone")}</label>
        <input id="phoneNumber" type='text' name="phoneNumber" {...register("phoneNumber")}/>
      </div>

      <div className='form-control'>
        <label>{t("Form.email")}</label>
        <input id="email" type='email' name="email" {...register("email")}/>
      </div>

      <div className='form-control'>
        <label>{t("Form.hdykm?")}</label>
        <input id="hdykm" type='text' name="hdykm" {...register("hdykm")}/>
      </div>

      <div className='form-control'>
        <label>{t("Form.message")}</label>
        <textarea id="message" name="message" {...register("message")} />
      </div>

      <div className='form-control'>
        <Button style={{padding: "0.6rem 4rem"}} content="submit"/>
      </div>
    </form>
  )
}
