import React, {useRef} from 'react'
import "./Review.css"
import Card from "../../Components/Card/Card"
import { useTranslation } from 'react-i18next';


export default function Review() {

  const {t} = useTranslation();

  const review1 = t("Feedback.review1")
  const review2 = t("Feedback.review2")
  const review3 = t("Feedback.review3")

  const reviews = [
    {review1: [review1, "Marie Morlon"]},
    {review2: [review2, "Elodie Lourimi"]},
    {review3: [review3, "Anne-Claire Collot"]}
  ];

  const reviewRef = useRef();
  window.$review = reviewRef

  return (
    <section ref={reviewRef} style={{backgroundColor: "var(--clr-white-200)"}}>
    <div className="container">
      <h2 className='text-center'>{t("Feedback.title")}</h2>
      <div className="reviews">
        {
          reviews.map((review) => {
            return(
              Object.entries(review).map(([key, value], index)=> {
                return(
                  <Card review={value[0]} reviewer={value[1]} key={index}/>
                )
              })
            )
          })
        }
      </div>
    </div>
  </section>
  )
}
