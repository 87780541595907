import React from 'react'
import "./Card.css"

export default function Card(props) {
  return (
    <div className='card'>

      <div>
        <p><span>" </span>{props.review}<span> "</span></p>
      </div>

      <div>
        <h4>{props.reviewer}</h4>
      </div>

    </div>
  )
}
