import React from 'react'
import "./Button.css"
import { useTranslation} from 'react-i18next';

export default function Button(props) {

  const {t} = useTranslation();

  const contentBtn = props.content;
  const styleAdditionnal = props.style

  const linkAppointment = "https://calendly.com/elisabethreflexoreiki/reflexologie-reiki?fbclid=IwAR1V7Y0XjbiMRA636arOdoTRm_YG_XnUUQ35XzCTOV7GinKguJcFYnMMfMQ&month=2023-04"

  return (
    <a href={linkAppointment} rel="noreferrer" target="_blank">
      <button className='btn' style={styleAdditionnal}>
        {t(`Button.${contentBtn}`)}
      </button>
    </a>
  )
}
