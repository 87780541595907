import React, { useState, useEffect } from 'react'
import "./Navbar.css"
import Burger from "./Burger"

export default function Navbar() {


  const [fix, setFix] = useState(false);

  useEffect(()=> {
    const setFixed = () => {
      if (window.scrollY >=380) {
        setFix(true);
      } else {
        setFix(false);
      }
    }
    window.addEventListener("scroll", setFixed)
  })



  return (
    <nav className={fix ? 'navbar-fixed' : "navbar"}>
      <Burger/>
    </nav>
  )
}
