import React from 'react'
import {Link} from "react-router-dom"
import "./NotFound.css"

export default function NotFound() {
  return (
    <section className='notFound'>
      <div className='content-notFound'>
        <span>404</span>
        <h1>Oups page not found!</h1>
        <Link to="/" className='link-home'>Take me home</Link>
      </div>
    </section>
  )
}
