import React from 'react'
import Button from "../../Components/Button/Button"
import "./Definition.css"
import reiki from "../../Assets/Logo/reiki.png"
import reflexo from "../../Assets/Logo/footReflexo.png"



import { useTranslation } from 'react-i18next';

export default function Definition() {

  const {t} = useTranslation();

  return (
    <>
      <section>
        <div className="container">
          <h2 className='text-center'>{t("Definition.title")}</h2>
          <div className="split split-expertise">
            <div className='card-expertise'>
              <div>
                <img src={reflexo} alt="reflexo" />
              </div>
              <h3>Reflexologie</h3>
              <p>{t("Definition.firstParagraph")}</p>
              <Button content="appointment"/>
            </div>
            <div className='card-expertise'>
              <div>
                <img src={reiki} alt="reiki" />
              </div>
              <h3>Reiki</h3>
              <p>{t("Definition.secondParagraph")}</p>
              <Button content="appointment"/>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}
