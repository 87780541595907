import React from 'react'
import Reflexologie from "../../Assets/Img/reflexoTradi.jpg"
import Reiki from "../../Assets/Img/reiki04.jpg"
import Button from "../../Components/Button/Button"
import { useTranslation } from 'react-i18next';
import "./Soin.css"


export default function Soin() {

  const {t} = useTranslation();



  return (
    <>
      <section>
        <div className="container">
          <div className="split">
            <div>
              <h2>{t("Care.title1")}</h2>
              <p>{t("Care.firstCare")}</p>
              <p><strong>{t("Care.subtitle")}</strong></p>
              <ul style={{marginLeft: "1rem", marginBottom: "2rem"}}>
                <li>{t("Care.ul1.li1")}</li>
                <li>{t("Care.ul1.li2")}</li>
                <li>{t("Care.ul1.li3")}</li>
                <li>{t("Care.ul1.li4")}</li>
                <li>{t("Care.ul1.li5")}</li>
                <li>{t("Care.ul1.li6")}</li>
                <li>{t("Care.ul1.li7")}</li>
                <li>{t("Care.ul1.li8")}</li>
                <li>{t("Care.ul1.li9")}</li>
                <li>{t("Care.ul1.li10")}</li>
              </ul>
              <Button style={{marginBottom: "1.5rem"}} content="appointment"/>
            </div>
            <div>
              <img style={{marginTop: "10rem", marginLeft: "1.5rem", objectPosition:"center left"}} className="img-care" src={Reflexologie} alt=''/>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-primary'>
        <div className="container">
          <div className="split">
            <div>
              <img style={{marginTop: "8rem"}} className="img-care" src={Reiki} alt=''/>
            </div>
            <div>
              <h2>{t("Care.title2")}</h2>
              <p>{t("Care.secondCare")}</p>
              <p><strong>{t("Care.subtitle")}</strong></p>
              <ul style={{marginLeft: "1rem", marginBottom: "2rem"}}>
                <li>{t("Care.ul2.li1")}</li>
                <li>{t("Care.ul2.li2")}</li>
                <li>{t("Care.ul2.li3")}</li>
                <li>{t("Care.ul2.li4")}</li>
                <li>{t("Care.ul2.li5")}</li>
                <li>{t("Care.ul2.li6")}</li>
                <li>{t("Care.ul2.li7")}</li>
                <li>{t("Care.ul2.li8")}</li>
                <li>{t("Care.ul2.li9")}</li>
                <li>{t("Care.ul2.li10")}</li>
              </ul>
              <Button style={{marginBottom: "1.5rem"}} content="appointment"/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
