import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Banner from '../../Sections/Banner/Banner'
import Definition from '../../Sections/Definition/Definition'
import Soin from '../../Sections/Soin/Soin'
import Review from '../../Sections/Review/Review'
import AboutMe from '../../Sections/AboutMe/AboutMe'
import Contact from '../../Sections/Contact/Contact'
import NewFooter from '../../Sections/Footer/NewFooter'
import Session from '../../Sections/Session/Session'

export default function Home() {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <AboutMe/>
      <Definition/>
      <Session/>
      <Soin/>
      <Review/>
      <Contact/>
      <NewFooter/>
    </div>
  )
}
