import React from 'react'
import "./AboutMe.css"
// import avatar from "../../Assets/Img/EliAvatar.jpg"
import avatar from "../../Assets/Img/AvatarEli.jpg"
import { useTranslation } from 'react-i18next';


export default function AboutMe() {
  const {t} = useTranslation();

  return (
    <section>
      <div className="container">
        <h2 className='text-center'>{t("WAI?.title")}</h2>
        <div className="split split-author">
          <div className='img-author'>
            <img className="avatar-me" src={avatar} alt='me'/>
          </div>
          <div className='text-author'>
            <p style={{marginBottom: "3rem"}}>{t("WAI?.1")}</p>
            <p style={{marginBottom: "3rem"}}>{t("WAI?.2")}</p>
            <p>{t("WAI?.3")}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
