import React, {useState} from 'react';
import styled from 'styled-components';
import "./RightNavbar.css";
import { useTranslation } from 'react-i18next';


const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;


  @media (max-width: 770px) {
    flex-flow: column nowrap;
    align-items: flex-start;

    background-color: var(--clr-primary-400);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;

    height: 100vh;
    width: 350px;
    padding-top: 4.7rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: var(--clr-cream-100);
    }
  }
`;

export default function RightNav({open}) {

  const {t, i18n} = useTranslation();

// CHANGE LANGUAGE
const [toggleLanguage, setToggleLanguage] = useState(true);

const changeLanguage = () => {
  setToggleLanguage(!toggleLanguage);

  if(i18n.language === "en") {
    i18n.changeLanguage("fr");


  } else if(i18n.language === "fr") {
    i18n.changeLanguage("en");
  }
}

const care = window.$care;
const contact = window.$contact;
const home = window.$home
const review = window.$review


  return (

    <Ul open={open}>
      <li className='navlink' onClick={() => home.current.scrollIntoView({behaviour:"smooth", block: "center"})}>{t("Navbar-title.home")}</li>
      <li className='navlink' onClick={() => care.current.scrollIntoView({behaviour:"smooth", block: "start"})}>{t("Navbar-title.care")}</li>
      <li className='navlink' onClick={() => contact.current.scrollIntoView({behaviour:"smooth", block: "start"})}>{t("Navbar-title.contacts")}</li>
      <li className='navlink' onClick={() => review.current.scrollIntoView({behaviour:"smooth", block: "start"})}>{t("Navbar-title.feedback")}</li>
      <li className='navlink' onClick={() => changeLanguage()}>{toggleLanguage ? "En" : "Fr"}</li>
    </Ul>
  )
}
