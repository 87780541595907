import React, {useRef} from 'react';
import "./Banner.css"
import Button from "../../Components/Button/Button"
import { useTranslation } from 'react-i18next';


export default function Banner() {

  const {t} = useTranslation();

  const homeRef = useRef();
  window.$home = homeRef


  return (
    <header ref={homeRef} className="banner">
      <div className="container-banner text-center">
        <div className='container container--narrow '>
          <h1>{t("Banner.title")}</h1>
          <p>{t("Banner.subtitle1")}{t("Banner.subtitle2")}<strong>{t("Banner.subtitle3")}</strong></p>
          <Button content="appointment"/>
        </div>
      </div>
    </header>
  )
}
