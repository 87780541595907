import React, {useRef} from 'react'
import Form from "../../Components/Form/Form"
import "./Contact.css"


export default function Contact() {

  const contactRef = useRef();
  window.$contact = contactRef;

  return (
    <section ref={contactRef}>
      <div className="container">
        <h2 className='text-center'>Contact</h2>
        <div className="container-form">
          <Form/>
        </div>
      </div>
    </section>
  )
}
