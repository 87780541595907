import React, {useState, useRef} from 'react'
import "./NewFooter.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { useTranslation} from 'react-i18next';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function NewFooter() {
  const {t} = useTranslation();

  const linkGoogle = "https://www.google.com/maps/place/Elisabeth+Henry+-+r%C3%A9flexologue/@45.5591827,-73.6430851,11z/data=!4m6!3m5!1s0x45251a93e09ef48d:0xe63c6f904048bb38!8m2!3d45.5591827!4d-73.6430851!16s%2Fg%2F11tsgxwj4m"

  const [showModalCopied, setShowModalCopied] = useState(false)

  const showModalCopiedHandler = () => {
    setShowModalCopied(true);
    setTimeout(() => {
      setShowModalCopied(false);
    }, 1000);
  }
  const footerRef = useRef();
  window.$footer = footerRef;

  return (
    <section ref={footerRef} style={{backgroundColor: "var(--clr-white-200)"}}>
      <div className="container new-footer">
        <div>
          <h3>Elisabeth Henry</h3>
          <ul>
            <CopyToClipboard text="example@gmail.com">
              <li style={{position: "relative"}} onClick={showModalCopiedHandler}>
                <FontAwesomeIcon id="icon-envelopp" style={{fontSize: "1.5rem"}} icon={faEnvelope} />
                helisabeth2890@gmail.com
                {showModalCopied &&
                <div className='alert'>
                  <div className='alert-copied'>
                    Copied!
                  </div>
                </div>
                }
              </li>
            </CopyToClipboard>
            <li><FontAwesomeIcon id="icon" style={{fontSize: "1.5rem"}}icon={faPhone} />(514) 561-2269</li>
          </ul>
        </div>

        <div>
          <h3>{t("Footer.openingHours")}</h3>
          <ul>
            <li>{t("Footer.monday")}</li>
            <li>{t("Footer.tuesday")}</li>
            <li>{t("Footer.wednesday")}</li>
            <li>{t("Footer.thursday")}</li>
            <li>{t("Footer.friday")}</li>
            <li>{t("Footer.saturday")}</li>
            <li>{t("Footer.sunday")}</li>
          </ul>
        </div>

        <div className='social-media'>
          <h3>{t("Footer.followMe")}</h3>
          <ul>
            <li><FontAwesomeIcon id="icon" icon={faInstagram}/></li>
            <li><a href={linkGoogle} rel="noreferrer" target="_blank"><FontAwesomeIcon id="icon" icon={faGoogle} /></a></li>
          </ul>
        </div>
      </div>
      <footer><small>Copyright © 2023 Cherry Compagny Tous droits réservés.</small></footer>
    </section>
  )
}
