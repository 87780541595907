import React, {useRef} from 'react'
import "./Session.css"
import { useTranslation } from 'react-i18next';
import imgSession from "../../Assets/Img/reflexo1.jpg"
import Button from "../../Components/Button/Button"


export default function Session() {
  const {t} = useTranslation();

  const careRef = useRef();
  window.$care = careRef;

  return (
    <section ref={careRef} style={{padding: 0}}>
      {/* <div className="container"> */}
        <h2 className='text-center'>{t("Session.title")}</h2>
        <div className="card-session">
          <img src={imgSession} alt="img foot"/>
          <div className="card-session-infos">
            <div className="split">
              <div className='column-session'>
                {/* <h3>{t("Session.subtitle1")}</h3> */}
                <p>{t("Session.sentence1")}</p>
                <p>{t("Session.sentence2")}</p>
                <p style={{fontStyle: "italic"}}>{t("Session.duration1")}</p>
                <span><strong>{t("Session.price1")}</strong></span>
                <Button content="appointment"/>
              </div>
              {/* <div  className='column-session'>
                <h3>{t("Session.subtitle2")}</h3>
                <p>{t("Session.sentence2")}</p>
                <p style={{fontStyle: "italic"}}>{t("Session.duration2")}</p>
                <span><strong>{t("Session.price2")}</strong></span>
                <Button content="appointment"/>
              </div> */}
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  )
}
